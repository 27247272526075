import SubmitBlack from "Components/Button/SubmitBlack/SubmitBlack";
import ItemsInputWithLabel from "Components/Input/ItemsInputWithLabel/ItemsInputWithLabel";
import useRefrechUser from "Hooks/useRefrechUser";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import customAxios from "Utils/axios/axios";

function NutriServices() {
  const { user } = useSelector((state) => state.UserReducer);
  const [loading, setLoading] = React.useState(false);
  const { refrechUser } = useRefrechUser();

  const [form, setForm] = React.useState({
    ConsultMode: [],
    ServicesNut: [],
    aprocheNut: [],
  });

  useEffect(() => {
    if (user) {
      setForm({
        ConsultMode: user.ConsultMode,
        ServicesNut: user.ServicesNut,
        aprocheNut: user.aprocheNut,
      });
    }
  }, [user]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);
    customAxios
      .put(`/api/nutritionist/update/${user._id}`, {
        ...form,
      })
      .then((res) => {
        toast.success("Updated successfully");
        refrechUser();
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error updating data");
        setLoading(false);
      });
  };

  return (
    <div>
      <ItemsInputWithLabel
        route="/api/consult-mode/get-all"
        label="Consultation Mode"
        value={form.ConsultMode}
        name={"ConsultMode"}
        onChange={handleChange}
      />

      <hr className="my-[2vw]" />

      <ItemsInputWithLabel
        route="/api/nutr-service/get-all"
        label="Services"
        value={form.ServicesNut}
        name={"ServicesNut"}
        onChange={handleChange}
      />

      <hr className="my-[2vw]" />

      <ItemsInputWithLabel
        route="/api/approche-nutritionnelle/get-all"
        label="Approche Nutritionnelle"
        value={form.aprocheNut}
        name={"aprocheNut"}
        onChange={handleChange}
      />

      <SubmitBlack
        onClick={handleSubmit}
        className="ml-auto mt-[2vw]"
        loading={loading}
      >
        SAVE
      </SubmitBlack>
    </div>
  );
}

export default NutriServices;
