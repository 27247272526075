import React from "react";
import styles from "./GoBack.module.scss";
import { NavLink } from "react-router-dom";
import back_arrow from "../../Assets/Svgs/general_icons/back_arrow_white.svg";

function GoBack({ children = "", className = "", color = "white", to = "/" }) {
  return (
    <NavLink to={to} className={`${styles.main} ${className} ${styles[color]}`}>
      <img src={back_arrow} alt="" />
      <p>{children}</p>
    </NavLink>
  );
}

export default GoBack;
