import React, { useState } from "react";
import styles from "./FindTrainerPopup.module.scss";

import Dialogue from "../../../Components/Dialogue/Dialogue";
import Flex from "../../../Components/Flex/Flex";
import Button from "../../../Components/Button/Button";

import check_red from "../../../Assets/Svgs/general_icons/check_red.svg";
import circle_red from "../../../Assets/Svgs/general_icons/circle_red.svg";
import { NavLink } from "react-router-dom";

function FindTrainerPopup({ open = false, handle_close }) {
  const [step, setstep] = useState(0);

  const Steps = [Step1, Step2, Step3, Step4, Step5, Step6, Step7];

  const handle_next = () => {
    if (step < Steps.length - 1) {
      setstep(step + 1);
    }
  };

  const handle_prev = () => {
    if (step > 0) {
      setstep(step - 1);
    }
  };

  let Step = Steps[step];

  return (
    <Dialogue handleClose={handle_close} open={open}>
      {
        <Step
          handle_next={handle_next}
          handle_prev={handle_prev}
          handle_close={handle_close}
        />
      }
    </Dialogue>
  );
}

const Step1 = ({ handle_next = () => {}, handle_prev = () => {} }) => {
  return (
    <Flex flex="between" className={styles.main}>
      <div className={styles.header}>
        <h1>What’s your name ?</h1>
        <h3>Find Your Trainer and Reach Your Goals Faster!</h3>
      </div>

      <Flex className={styles.body}>
        <input type="text" placeholder="Your Name Here" />
      </Flex>

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handle_next}>Next</Button>
      </Flex>
    </Flex>
  );
};

const Step2 = ({ handle_next = () => {}, handle_prev = () => {} }) => {
  return (
    <Flex flex="between" className={styles.main}>
      <div className={styles.header}>
        <h3>Great to meet you , Name</h3>
        <h1>What are your fitness goals ?</h1>
      </div>

      <Flex className={styles.body}>
        <CheckText text="Lose Fate" />
        <CheckText text="Lose Fate" />
        <CheckText text="Lose Fate" />
        <CheckText text="Lose Fate" />
        <CheckText text="Lose Fate" />
      </Flex>

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handle_prev} color="black">
          Previus
        </Button>
        <Button onClick={handle_next}>Next</Button>
      </Flex>
    </Flex>
  );
};

const Step3 = ({ handle_next = () => {}, handle_prev = () => {} }) => {
  return (
    <Flex flex="between" className={styles.main}>
      <div className={styles.header}>
        <h1>What’s your current age ?</h1>
        <h3>Find Your Trainer and Reach Your Goals Faster!</h3>
      </div>

      <Flex className={styles.body}>
        <input type="number" placeholder="Your Age" />
      </Flex>

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handle_prev} color="black">
          Previus
        </Button>
        <Button onClick={handle_next}>Next</Button>
      </Flex>
    </Flex>
  );
};

const Step4 = ({ handle_next = () => {}, handle_prev = () => {} }) => {
  return (
    <Flex flex="between" className={styles.main}>
      <div className={styles.header}>
        <h1>
          How would you describe <br /> your gender ?
        </h1>
      </div>

      <Flex className={styles.body}>
        <RadioText text="Female" />
        <RadioText text="Male" />
        <RadioText text="Prefer not to say" />
      </Flex>

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handle_prev} color="black">
          Previus
        </Button>
        <Button onClick={handle_next}>Next</Button>
      </Flex>
    </Flex>
  );
};

const Step5 = ({ handle_next = () => {}, handle_prev = () => {} }) => {
  return (
    <Flex flex="between" className={styles.main}>
      <div className={styles.header}>
        <h1>
          what’s your biggest <br /> fitness challenge ?
        </h1>
      </div>

      <Flex className={styles.body}>
        <CheckText text="Lose Fate" />
        <CheckText text="Lose Fate" />
        <CheckText text="Lose Fate" />
        <CheckText text="Lose Fate" />
        <CheckText text="Lose Fate" />
      </Flex>

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handle_prev} color="black">
          Previus
        </Button>
        <Button onClick={handle_next}>Next</Button>
      </Flex>
    </Flex>
  );
};

const Step6 = ({ handle_next = () => {}, handle_prev = () => {} }) => {
  return (
    <Flex flex="between" className={styles.main}>
      <div className={styles.header}>
        <h1>
          Where do you plan on <br /> working out ?
        </h1>
      </div>

      <Flex className={styles.body}>
        <RadioText text="At home" />
        <RadioText text="In a gym" />
        <RadioText text="Both" />
        <RadioText text="Am not sure yet" />
      </Flex>

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handle_prev} color="black">
          Previus
        </Button>
        <Button onClick={handle_next}>Next</Button>
      </Flex>
    </Flex>
  );
};

const Step7 = ({ handle_prev = () => {}, handle_close = () => {} }) => {
  return (
    <Flex flex="between" className={styles.main}>
      <div className={styles.header}>
        <h2>
          Thank you, Name! that’s are all <br /> the info we need for now !
        </h2>
        <p className={styles.desc}>
          Welcome to <strong>TRENA</strong>, where we're more than just a
          fitness platform; we're <br /> your dedicated partner in crafting a
          healthier life. Our mission <br /> encompasses three vital pillars:
        </p>
      </div>

      <Flex className={styles.body}>
        <input type="text" placeholder="exemple.12@gmail.com" />
      </Flex>

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handle_prev} color="black">
          Previus
        </Button>
        <Button onClick={handle_close}>
          <NavLink to="/personal-coach"> Finish</NavLink>
        </Button>
      </Flex>
    </Flex>
  );
};

export const CheckText = ({ text = "" }) => {
  const [checked, setchecked] = useState(false);
  let checked_style = checked ? styles.checked : "";

  const handle_check = () => {
    setchecked(!checked);
  };

  return (
    <Flex
      flex="start"
      className={`${styles.check} ${checked_style}`}
      onClick={handle_check}
    >
      <Flex className={styles.checkbox}>
        {checked && <img src={check_red} alt="" />}
      </Flex>
      <h2 className={styles.text}>{text}</h2>
    </Flex>
  );
};

const RadioText = ({ text = "" }) => {
  const [checked, setchecked] = useState(false);
  let checked_style = checked ? styles.checked : "";

  const handle_check = () => {
    setchecked(!checked);
  };

  return (
    <Flex
      flex="start"
      className={`${styles.check} ${checked_style}`}
      onClick={handle_check}
    >
      <Flex className={`${styles.checkbox} ${styles.radiobox}`}>
        {checked && <img src={circle_red} alt="" />}
      </Flex>
      <h2 className={styles.text}>{text}</h2>
    </Flex>
  );
};

export default FindTrainerPopup;
