import React, { useEffect, useState } from "react";
import styles from "./TrenaPedia.module.scss";
import H1 from "../../Components/H/H1";
import useForm from "../../Hooks/UseForm";
import SearchIcon from "../../Assets/Svgs/general_icons/search_black.svg";
import Blog1 from "../../Assets/Images/Tempo/blogs/Rectangle 34.png";
import more_icon from "../../Assets/Svgs/general_icons/go_arrow_black.svg";
import { NavLink, useNavigate } from "react-router-dom";
import Rates from "../../Components/Rates/Rates";
import { GapNavbarSpacer } from "../../Layouts/Navbar/Navbar";
import customAxios from "../../Utils/axios/axios";
import ImgBack from "../../Components/Image/ImgBack";

function TrenaPedia() {
  const [form, handleChange] = useForm({ search: "" });
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    customAxios.get("/api/blogs/get-all").then((res) => {
      setBlogs(res.data.data);
    });
  }, []);

  return (
    <div className={styles.main}>
      <GapNavbarSpacer />
      <H1 className={styles.h1}>Want Better Results?</H1>
      <p className={styles.main_descrp}>
        Welcome to <strong> TRENA </strong>, where we're more than just a
        fitness platform; we're your dedicated <br /> partner in crafting a
        healthier life. Our mission encompasses three vital pillars:
      </p>

      <div className={styles.search_input}>
        <input
          type="text"
          value={form.search}
          placeholder="Search"
          onChange={handleChange}
          name="search"
          id=""
        />
        <img src={SearchIcon} alt="" />
      </div>

      <div className={styles.blogs}>
        {blogs.map((blog) => (
          <Blog key={blog.id} blog={blog} />
        ))}
      </div>
    </div>
  );
}

export const Blog = ({
  blog = {
    _id: "",
    author: "Trena",
    authorImg: "",
    title: "",
    description: "",
    image: "",
  },
}) => {
  const navigate = useNavigate();
  const goto = () => {
    navigate(`/trenapedia/${blog._id}`);
  };

  return (
    <div className={styles.blog} onClick={goto}>
      <div className={styles.img}>
        <ImgBack src={blog.image} alt="" />
      </div>
      <div className={styles.content}>
        <div>
          <span>{blog.author.toUpperCase()}</span>
          <h2>{blog.title}</h2>
          <p>{blog.description}</p>
        </div>

        {/* <div className={styles.blog_footer}>
          <Rates number={5} />

          <NavLink to={`/trenapedia/${blog._id}`} className={styles.more}>
            <span>Read</span>
            <img src={more_icon} alt="" />
          </NavLink>
        </div> */}
      </div>
    </div>
  );
};

export default TrenaPedia;
