import React from "react";

function TowItems({ children, className = "" }) {
  return (
    <div
      className={`w-full flex items-start justify-between space-x-[2vw] mb-[2vw] ${className}`}
    >
      {children}
    </div>
  );
}

export default TowItems;
