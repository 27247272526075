import React from "react";
import customAxios from "Utils/axios/axios";

const ItemsInputWithLabel = ({
  route = "/",
  label = "",
  labelClassName = "",
  onChange = () => {},
  name = "",
  value = [],
}) => {
  const [services, setServices] = React.useState([]);

  React.useEffect(() => {
    customAxios.get(route).then((res) => {
      setServices(res.data.data);
    });
  }, []);

  const handleChange = (_id) => {
    let newServices = [...value];
    const index = newServices.findIndex(
      (curren_serv_id) => curren_serv_id === _id
    );
    if (index === -1) {
      newServices.push(_id);
    } else {
      // remove the service
      newServices.splice(index, 1);
    }

    onChange({ target: { name, value: newServices } });
  };

  return (
    <div className="w-full mb-[2vw]">
      <label
        className={`text-[1.5vw] mb-[0.8vw] block font-medium ${labelClassName}`}
      >
        {label}
      </label>

      <div className="flex items-center justify-start flex-wrap gap-x-[1vw] gap-y-[1vw]">
        {services
          .sort((serv1, serv2) => {
            // sort by title length
            return serv1.title.length - serv2.title.length;
          })
          .map((service, index) => (
            <Service
              key={index}
              service={service}
              onClick={() => handleChange(service._id)}
              active={value.includes(service._id)}
            />
          ))}
      </div>
    </div>
  );
};

const Service = ({ service, active, onClick = () => {} }) => {
  let containerCss = active
    ? "border-2 border-black font-bold bg-gray-50"
    : "border border-border";

  return (
    <div
      onClick={onClick}
      className={`px-[2vw] py-[1vw] w-[48%] rounded-[0.8vw] flex items-center  justify-between cursor-pointer ${containerCss}`}
    >
      <div className="text-[1vw] overflow-ellipsis select-none">
        {service.title}
      </div>
    </div>
  );
};

export default ItemsInputWithLabel;
