import React, { useEffect, useState } from "react";
import styles from "./OneBlog.module.scss";
import Rates from "../../Components/Rates/Rates";

import GoBack from "../../Components/GoBack/GoBack";
import { blogData } from "./data";
import { useParams } from "react-router-dom";
import customAxios from "../../Utils/axios/axios";
import ImgBack from "../../Components/Image/ImgBack";

function OneBlog() {
  const { id } = useParams();

  const [blog, setBlog] = useState(null);

  useEffect(() => {
    customAxios
      .get(`/api/blogs/get-one/${id}`)
      .then((resp) => {
        console.log(resp.data.data);
        setBlog(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  if (!blog) {
    return <></>;
  }

  return (
    <div className={styles.main}>
      <div className={styles.head}>
        <GoBack to="/trenapedia" color="black" className={styles.GoBack}>
          Back
        </GoBack>

        <Rates number={5} />
      </div>

      <div className={styles.blog}>
        <h1>{blog.title}</h1>

        <div className={styles.content}>
          {blog.content.map((item, index) => {
            if (item.type === "Image") {
              return <ImgBack key={index} src={item.value} alt={blog.title} />;
            }

            if (item.type === "Title") {
              return <h3 key={index}>{item.value}</h3>;
            }

            if (item.type === "Paragraph") {
              return <p key={index}>{item.value}</p>;
            }
          })}
        </div>
        {/* <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: blogData.content }}
        /> */}
      </div>
    </div>
  );
}

export default OneBlog;
