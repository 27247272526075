import React from "react";
import styles from "./cardCoach.module.scss";

import { useNavigate } from "react-router-dom";
import ImgBack from "../../Image/ImgBack";

const CardNut = ({ data, className = "", white_card = false }) => {
  let white_card_class = white_card ? styles.white_card : "";
  const navig = useNavigate();

  const goto = () => {
    navig(`/personal-nutrit/${data._id}`);
  };

  return (
    <div
      className={`${styles.trainer} ${className} ${white_card_class}`}
      onClick={goto}
    >
      <div className={styles.img}>
        <ImgBack src={data.MainImg} alt="" />
      </div>

      <div className={styles.info}>
        <div className={styles.head}>
          <h3>{data?.fullname}</h3>
        </div>
        <div className={styles.body}>
          <p>
            <span>Location</span> : {data.Location}
          </p>
          <p>
            <span>Experience</span> : More than{" "}
            {new Date().getFullYear() -
              new Date(data.DateStartExp).getFullYear()}{" "}
            {/* show year or years */}
            {new Date().getFullYear() -
              new Date(data.DateStartExp).getFullYear() >
            1
              ? "years"
              : "year"}
          </p>

          <p>
            <span>Speciality</span> : {data.speciality}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardNut;
