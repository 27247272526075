import React from "react";

function InputWithLabelAndIcon({
  label = "",
  type = "text",
  className = "",
  labelClassName = "",
  inputClassName = "",
  icon = "/svgs/rxs/ww.svg",
  ...props
}) {
  return (
    <div className={`w-full ${className}`}>
      <label
        className={`text-[1.1vw] mb-[0.8vw] block font-medium ${labelClassName}`}
      >
        {label}
      </label>
      <div className="rounded-[0.8vw] pl-[1vw] w-full flex items-center justify-start  border-border border overflow-hidden">
        <img src={icon} alt={label} className="w-[2.3vw]" />
        <input
          {...props}
          type={type}
          className={`text-[1.1vw] px-[1.5vw] py-[0.8vw] w-full outline-none  ${inputClassName}`}
        />
      </div>
    </div>
  );
}

export default InputWithLabelAndIcon;
