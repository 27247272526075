import React from "react";
import styles from "./Login.module.scss";

import image from "../../Assets/Images/login.jpg";
import image2 from "../../Assets/Images/login2.jpg";
import logo from "../../Assets/Svgs/logo_black.svg";

import Flex from "../../Components/Flex/Flex";
import Button from "../../Components/Button/Button";
import { NavLink } from "react-router-dom";
import useForm from "../../Hooks/UseForm";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ActionRegister, ActionLogin } from "../../Store/user.reducer";
import FacebookLog from "./FacebookLog";
import GoogleAuth from "./GoogleAuth";
import TextInput from "../../Components/Input/TextInput/TextInput";
import PasswordInput from "../../Components/Input/PasswordInput/PasswordInput";

export const Login = () => {
  return (
    <Flex className={styles.main}>
      <div className={styles.img_container}>
        <img src={image2} alt="" />
      </div>
      <Flex className={styles.content}>
        <OnlyLoginRight />
      </Flex>
    </Flex>
  );
};

export const Register = () => {
  return (
    <Flex className={styles.main}>
      <div className={styles.img_container}>
        <img src={image} alt="" />
      </div>
      <Flex className={styles.content}>
        <OnlyRegisterRight />
      </Flex>
    </Flex>
  );
};

export const OnlyRegisterRight = ({ openOther = () => {} }) => {
  const dispatch = useDispatch();

  const [form, handleChange] = useForm({
    firstName: "",
    lastName: "",
    phoneCode: "+216",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = () => {
    if (form.password !== form.confirmPassword) {
      toast.error("passwords are not the same");
      return;
    }

    const phoneNumber = form.phoneCode + form.phone;
    const fullname = form.firstName + " " + form.lastName;
    const email = form.email;
    const password = form.password;

    const user = {
      fullname,
      email,
      password,
      phoneNumber,
    };
    dispatch(ActionRegister(user, () => {}));
  };

  return (
    <div className={styles.container}>
      <Header />
      <span className="hidden">
        <WithGLAndFB type="Register" />
        <Flex className={styles.separ}>
          <span>Or</span>
        </Flex>
      </span>

      <div className={styles.form}>
        <div className={styles.row}>
          <TextInput
            type="text"
            className={styles["w-50"]}
            placeholder="First Name"
            value={form.firstName}
            name="firstName"
            onChange={handleChange}
          />
          <TextInput
            type="text"
            className={styles["w-50"]}
            placeholder="Last Name"
            value={form.lastName}
            name="lastName"
            onChange={handleChange}
          />

          <TextInput
            type="text"
            className={styles["w-25"]}
            placeholder="+216"
            disabled={true}
            value={form.phoneCode}
            name="phoneCode"
            onChange={handleChange}
          />
          <TextInput
            type="text"
            className={styles["w-75"]}
            placeholder="Phone Number ..."
            value={form.phone}
            name="phone"
            onChange={handleChange}
          />

          <TextInput
            type="text"
            className={styles["w-100"]}
            placeholder="Email"
            value={form.email}
            name="email"
            onChange={handleChange}
          />

          <PasswordInput
            className={styles["w-100"]}
            placeholder="Password"
            value={form.password}
            name="password"
            onChange={handleChange}
          />

          <PasswordInput
            className={styles["w-100"]}
            placeholder="Confirm Password"
            value={form.confirmPassword}
            name="confirmPassword"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className={styles.btn_cont}>
        <Button onClick={handleSubmit}>Register</Button>
      </div>

      <div className={styles.otherOptin}>
        <span>Already have an account ? </span>
        <span className={styles.a} onClick={openOther}>
          Login
        </span>
      </div>
    </div>
  );
};

export const OnlyLoginRight = ({ openOther = () => {} }) => {
  const dispatch = useDispatch();
  const [form, handleChange] = useForm({
    email: "",
    password: "",
  });
  const [remember, setRemember] = React.useState(false);

  const handleSubmit = () => {
    dispatch(ActionLogin({ ...form, remember }, () => {}));
  };

  return (
    <div className={styles.container}>
      <Header />
      <span className="hidden">
        <WithGLAndFB type="Login" />
        <Flex className={styles.separ}>
          <span>Or</span>
        </Flex>
      </span>

      <div className={styles.form}>
        <div className={styles.row}>
          <TextInput
            type="text"
            className={styles["w-100"]}
            placeholder="Email"
            value={form.email}
            name="email"
            onChange={handleChange}
          />
        </div>

        <div className={styles.row}>
          <PasswordInput
            type="password"
            className={styles["w-100"]}
            placeholder="Password"
            value={form.password}
            name="password"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.remember}>
          <input
            type="checkbox"
            checked={remember}
            onChange={() => setRemember(!remember)}
          />
          <span>Remember me</span>
        </div>
        <span>Forgot your password ?</span>
      </div>

      <div className={styles.btn_cont}>
        <Button onClick={handleSubmit}>LOGIN</Button>
      </div>

      <div className={styles.otherOptin}>
        <span>Are you new here ? </span>
        <span className={styles.a} onClick={openOther}>
          Create an account
        </span>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className={styles.header}>
      <NavLink to="/">
        <img src={logo} alt="" className={styles.logo} />
      </NavLink>
      <h2>Welcome!</h2>
    </div>
  );
};

const WithGLAndFB = ({ type = "Login" }) => {
  return (
    <Flex flex="between" className={styles.rxs}>
      <FacebookLog txt={`${type} With Facebook`}></FacebookLog>
      <GoogleAuth txt={`${type} With Google`} />
    </Flex>
  );
};

export const WithRxsBtn = ({ img, txt, onClick = () => {} }) => {
  return (
    <button onClick={onClick}>
      <img src={img} alt="" /> <span> {txt} </span>
    </button>
  );
};
