import { useState, useEffect } from "react";

function useCondRet(boolValue, trueString, falseString) {
  const [result, setResult] = useState("");

  useEffect(() => {
    setResult(boolValue ? trueString : falseString);
  }, [boolValue, trueString, falseString]);

  return result;
}

export default useCondRet;
