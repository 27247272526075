import React from "react";
import Dialogue from "../../../Components/Dialogue/Dialogue";
import styles from "./RegisterPopup.module.scss";
import { OnlyRegisterRight } from "../../Auth/Auth";

function RegisterPopup({
  open = false,
  handle_close = () => {},
  openOther = () => {},
}) {
  return (
    <Dialogue handleClose={handle_close} open={open}>
      <div className={styles.main}>
        <OnlyRegisterRight openOther={openOther} />
      </div>
    </Dialogue>
  );
}

export default RegisterPopup;
