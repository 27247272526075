import React from "react";
import customAxios from "Utils/axios/axios";
import toast from "react-hot-toast";
import SubmitBlack from "Components/Button/SubmitBlack/SubmitBlack";
import PasswordWithLabel from "Components/Input/PasswordWithLabel/PasswordWithLabel";

function PasswordChange({ role = "" }) {
  const [loading, setLoading] = React.useState(false);

  const [form, setForm] = React.useState({
    oldPass: "",
    newPass: "",
    confirmPass: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (form.newPass !== form.confirmPass) {
      toast.error("Passwords do not match");
      return;
    }

    setLoading(true);
    customAxios
      .put(`/api/user/change-password`, {
        ...form,
      })
      .then((res) => {
        toast.success("Updated successfully");
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Please enter the correct old password");
        setLoading(false);
      });
  };

  return (
    <div className="w-full">
      <PasswordWithLabel
        label="Old password"
        className="mb-[2vw]"
        placeholder="Enter your old password"
        name="oldPass"
        value={form.oldPass}
        onChange={handleChange}
      />

      <PasswordWithLabel
        label="New password"
        className="mb-[2vw]"
        placeholder="Enter your new password"
        name="newPass"
        value={form.newPass}
        onChange={handleChange}
      />

      <PasswordWithLabel
        label="Confirm password"
        className="mb-[2vw]"
        placeholder="Confirm your new password"
        name="confirmPass"
        value={form.confirmPass}
        onChange={handleChange}
      />

      <SubmitBlack onClick={handleSubmit} className="ml-auto" loading={loading}>
        SAVE
      </SubmitBlack>
    </div>
  );
}

export default PasswordChange;
