import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from "redux";

import thunk from "redux-thunk";

import { UserReducer } from "./user.reducer";
import { CoacheReducer } from "./coaches.reducer";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const allReducers = combineReducers({
  UserReducer,
  CoacheReducer,
  // when user is connected , remove popups so no reload page needed
});

const store = createStore(allReducers, composeEnhancer(applyMiddleware(thunk)));

export default store;
