import React from "react";
import styles from "./About.module.scss";

import figure from "../../../Assets/Images/about.jpg";
import { data, data_types } from "./data";
import Button, { Righter } from "../../../Components/Button/Button";
import { NavLink } from "react-router-dom";

function About() {
  return (
    <div className={styles.main}>
      <img src={figure} alt="" className={styles.img} />
      <div className={styles.content}>
        <h1>ABOUT US</h1>
        <img src={figure} alt="" className={styles.mobie_img} />
        <p>
          We're revolutionizing fitness in <g>Tunisia</g> by connecting you with
          excellent fitness coaches and nutritionists for personalized training
          and nutrition guidance. Making it easier than ever to find the perfect
          experts to help you achieve your fitness goals. With personalized
          workout and nutrition plans designed just for you.
          {/* Welcome to <g>TRENA</g>, where we're more than just a fitness <br />{" "}
          platform; we're your dedicated partner in crafting a healthier <br />{" "}
          life. Our mission encompasses three vital pillars: */}
        </p>

        {data.map((item, index) => {
          return <ItemFitnes key={index} data={item} />;
        })}

        <Righter className={styles.btn}>
          <Button>
            <NavLink to="/about-us"> More about us </NavLink>
          </Button>
        </Righter>
      </div>
    </div>
  );
}

export const ItemFitnes = ({ data = data_types }) => {
  return (
    <div className={styles.item}>
      <div className={styles.icon}>
        <div className={styles.icon_container}>
          <img src={data.icon} alt="" className={styles[data.icon_class]} />
        </div>
      </div>
      <div className={styles.content}>
        <h3>{data.title}</h3>
        <p>{data.description}</p>
      </div>
    </div>
  );
};

export default About;
