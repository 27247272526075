import React from "react";
import styles from "./Rates.module.scss";

// import star from "./star.svg";
import StarIcon from "./star.svg";
import StarHalfIcon from "./halfStar.svg";

const MakeArray = (rates) => {
  let arr = [];
  for (let i = 0; i < rates; i++) {
    if (rates - i === 0.5) {
      arr.push(0.5);
    } else {
      arr.push(1);
    }
  }

  for (let i = 0; i < 5 - rates; i++) {
    arr.push(0);
  }

  return arr;
};

function Rates({ number = 5 }) {
  return (
    <div className={styles.rates}>
      {MakeArray(number).map((item, index) => {
        if (item === 1) {
          return <img color="#f5c002" key={index} src={StarIcon} alt="star" />;
        }

        if (item === 0.5) {
          return <img key={index} src={StarHalfIcon} alt="halfstar" />;
        }

        return null;
      })}
    </div>
  );
}

export default Rates;
