import React from "react";
import ImgBack from "../../../Components/Image/ImgBack";
import { useSelector } from "react-redux";

const TopHeader = () => {
  const { user } = useSelector((state) => state.UserReducer);

  return (
    <div className="w-full h-[10vw] flex items-center justify-center relative ">
      <ImgBack
        src={user.CoverProfileImage}
        altsrc="/images/cover.jpg"
        alt="settings"
        className="w-full h-full object-cover rounded-t-[2vw]"
      />
      <div className="h-[120%] w-[87%] absolute top-[55%] flex items-end justify-start">
        <div className="h-full overflow-hidden aspect-square rounded-full border-[0.4vw] border-white">
          <ImgBack
            src={user.MainImg}
            // altsrc="/images/user.png"
            alt="settings"
            className="w-full h-full object-cover bg-gray-200"
          />
        </div>
        <div className="ml-[1vw] w-1/2 h-[55%]">
          {/* <h1></h1> */}
          <h1 className="text-[1.8vw] font-semibold leading-1">
            {user?.fullname}
          </h1>
          <h3 className="text-[1.3vw] -mt-[0.5vw] text-textGray">
            Personal Coach
          </h3>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
