import React from "react";
const { REACT_APP_API_HOST } = process.env;

function ImgBack({
  src = "",
  alt = "",
  className = "",
  style = {},
  onClick = () => {},
  altsrc = "",
}) {
  // let backend_url = http://www.trena.pro;
  // let backend_url = process.env.REACT_APP_API_HOST;
  let backend_url = "https://trena.pro/api";
  let isOther = src?.indexOf("https") > -1;
  let imgurl = isOther ? src : src ? backend_url + src : altsrc;

  return (
    <img
      className={className}
      style={style}
      onClick={onClick}
      src={imgurl}
      alt={alt}
    />
  );
}

export default ImgBack;
