import { useGoogleLogin } from "@react-oauth/google";
import React from "react";
import { WithRxsBtn } from "./Auth";
import google_icon from "../../Assets/Svgs/iicon_rxs/google.svg";
import { useDispatch } from "react-redux";
import { ActionLoginGO } from "../../Store/user.reducer";

function GoogleAuth({ txt }) {
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      dispatch(ActionLoginGO(tokenResponse.access_token, () => {}));
    },
  });
  return (
    <>
      <WithRxsBtn img={google_icon} txt={txt} onClick={login} />
    </>
  );
}

export default GoogleAuth;
