export const Gender = [
  {
    label: "Male",
    value: "MEN",
  },

  {
    label: "Female",
    value: "WOMEN",
  },
];

export const Location = [
  {
    label: "Ariana",
    value: "Ariana",
  },
  {
    label: "Beja",
    value: "Beja",
  },
  {
    label: "Bizerte",
    value: "Bizerte",
  },
  {
    label: "El Kef",
    value: "El Kef",
  },
  {
    label: "Gabès",
    value: "Gabès",
  },
  {
    label: "Gafsa",
    value: "Gafsa",
  },
  {
    label: "Hammamet",
    value: "Hammamet",
  },
  {
    label: "Jendouba",
    value: "Jendouba",
  },
  {
    label: "Kairouan",
    value: "Kairouan",
  },
  {
    label: "Kasserine",
    value: "Kasserine",
  },
  {
    label: "La Marsa",
    value: "La Marsa",
  },
  {
    label: "Mahdia",
    value: "Mahdia",
  },
  {
    label: "Medenine",
    value: "Medenine",
  },
  {
    label: "Monastir",
    value: "Monastir",
  },
  {
    label: "Nabeul",
    value: "Nabeul",
  },
  {
    label: "Sfax",
    value: "Sfax",
  },
  {
    label: "Sousse",
    value: "Sousse",
  },
  {
    label: "Tataouine",
    value: "Tataouine",
  },
  {
    label: "Tozeur",
    value: "Tozeur",
  },
  {
    label: "Tunis",
    value: "Tunis",
  },
];

export const ServicesMidle = (services) => {
  return services.map((item) => {
    return {
      label: item?.title,
      value: item?._id,
    };
  });
};
