import React from "react";
import styles from "./TC.module.scss";
import H1 from "../../Components/H/H1";

import { GapNavbarSpacer } from "../../Layouts/Navbar/Navbar";
// hello

function TC() {
  return (
    <div className={styles.main}>
      <GapNavbarSpacer />
      <H1 className={styles.h1}>Privacy Policy and Terms of Use</H1>
      <div className={styles.content}>
        <h2>Privacy Policy</h2>
        <h3>Introduction</h3>
        <p>
          Your privacy is important to us. This Privacy Policy explains how we
          collect, use, and protect your personal information when you use our
          services.
        </p>
        <h3>Information We Collect</h3>

        <ul>
          <li>
            <strong>Personal Information:</strong> Name, email address, phone
            number, etc.
          </li>

          <li>
            <strong>Usage Data:</strong> Information about how you use our
            services, such as IP address, browser type, and access times.
          </li>
        </ul>

        <h3>How We Use Your Information</h3>
        <ul>
          <li>To provide and improve our services.</li>
          <li>To communicate with you.</li>
          <li>To personalize your experience.</li>
        </ul>

        <h3>How We Share Your Information</h3>
        <ul>
          <li>
            With third-party service providers to facilitate our services.
          </li>
          <li>When required by law or to protect our rights.</li>
        </ul>

        <h3>Security</h3>
        <p>
          We implement reasonable security measures to protect your information.
        </p>

        <h3>Your Choices</h3>
        <ul>
          <li>
            You can update your personal information through your account
            settings.
          </li>
          <li>
            You can opt-out of receiving marketing communications from us.
          </li>
        </ul>

        <h2>Terms of Use</h2>
        <h3>Acceptance of Terms</h3>
        <p>
          By using our services, you agree to these Terms of Use. If you do not
          agree, please do not use our services.
        </p>

        <h3>Use of Services</h3>
        <ul>
          <li>You must be at least 18 years old to use our services.</li>
          <li>You agree to use our services only for lawful purposes.</li>
        </ul>

        <h3>Account Responsibilities</h3>
        <ul>
          <li>
            You are responsible for maintaining the confidentiality of your
            account information.
          </li>
          <li>
            You agree to notify us immediately of any unauthorized use of your
            account.
          </li>
        </ul>

        <h3>Prohibited Activities</h3>
        <ul>
          <li>
            You may not use our services for any illegal or unauthorized
            purpose.
          </li>
          <li>You may not interfere with the operation of our services.</li>
        </ul>

        <h3>Intellectual Property</h3>
        <ul>
          <li>All content on our platform is owned by or licensed to us.</li>
          <li>You may not use any content without our written permission.</li>
        </ul>

        <h3>Termination</h3>
        <p>
          We reserve the right to terminate or suspend your account at any time
          for any reason.
        </p>

        <h3>Limitation of Liability</h3>
        <p>
          We are not liable for any damages arising from your use of our
          services.
        </p>

        <h3>Changes to These Terms</h3>
        <p>
          We may update these Terms of Use from time to time. We will notify you
          of any changes by posting the new terms on our website.
        </p>
      </div>
    </div>
  );
}

export default TC;
