import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { WithRxsBtn } from "./Auth";
import facebook_icon from "../../Assets/Svgs/iicon_rxs/facebook.svg";
import { useDispatch } from "react-redux";
import { ActionLogin, ActionLoginFB } from "../../Store/user.reducer";

function FacebookLog({ txt }) {
  const dispatch = useDispatch();

  const handleFacebookCallback = (response) => {
    if (response?.status === "unknown") {
      console.error("Sorry!", "Something went wrong with facebook Login.");
      return;
    }
    const { accessToken } = response;
    console.log(accessToken);
    dispatch(ActionLoginFB(accessToken, () => {}));
  };

  return (
    <>
      <FacebookLogin
        appId="1509893799644086"
        autoLoad={false}
        callback={handleFacebookCallback}
        fields="name,email,picture"
        render={(renderProps) => (
          <WithRxsBtn
            img={facebook_icon}
            txt={txt}
            onClick={renderProps.onClick}
          />
        )}
      />
    </>
  );
}

export default FacebookLog;
