import React, { useEffect, useState } from "react";
import styles from "./Trainers.module.scss";
import lines_white from "../../../Assets/Svgs/general_icons/3_lines_black.svg";

import Button, { Centerer } from "../../../Components/Button/Button";
import CardCoach from "../../../Components/Cards/CardCoach/CardCoach";
import { NavLink } from "react-router-dom";
import axios from "../../../Utils/axios/axios";

function Trainers() {
  const [coaches, setCoashes] = useState([]);

  useEffect(() => {
    axios
      .get("/api/coache/get-all")
      .then((resp) => {
        setCoashes(resp.data.data.reverse().filter((_, i) => i < 4));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className={styles.main}>
      <h1>
        Meet Our Trainers
        {/* <img src={lines_white} alt="" />{" "} */}
      </h1>
      <h4>Elevate Your Workout</h4>

      <div className={styles.trainers}>
        {coaches.map((item, index) => {
          return (
            <CardCoach
              white_card
              data={item}
              key={index}
              className={styles.coa}
            />
          );
        })}
      </div>

      <Centerer className={styles.btn}>
        <Button size="big">
          <NavLink to="/personal-coach">More Trainers</NavLink>
        </Button>
      </Centerer>
    </div>
  );
}

export default Trainers;
