import React from "react";
import styles from "./HeadPage.module.scss";

import temp_img from "../../../Assets/Images/home-head1.png";
import Button from "../../../Components/Button/Button";
import Flex from "../../../Components/Flex/Flex";
import FindTrainerPopup from "../../Pupups/FindTrainerPopup/FindTrainerPopup";
import usePopup from "../../../Hooks/usePupup";
import BeTrainerPopup from "../../Pupups/BeTrainerPopup/BeTrainerPopup";

function HeadPage() {
  const [open, handle_open, handle_close] = usePopup();
  const [open2, handle_open2, handle_close2] = usePopup();

  return (
    <div className={styles.main}>
      <FindTrainerPopup handle_close={handle_close} open={open} />
      <BeTrainerPopup handle_close={handle_close2} open={open2} />

      <img src={temp_img} alt="temp_img" className={styles.bg_img} />

      <div className={styles.content}>
        <h1>Unleash Your TRENA’thlete</h1>
        <h2>
          Tired of searching for the perfect fitness trainer or nutritionist ?{" "}
          <br /> Find top Tunisian experts matched to your exact goals.
        </h2>
        {/* <p>Personalized guidance from the top experts</p> */}
        <Flex className={styles.btns}>
          <Button
            size="verry-big"
            onClick={() => {
              setTimeout(() => {
                handle_open();
              }, 200);
            }}
          >
            Find a Trainer
          </Button>
          <Button
            size="verry-big"
            color="white"
            onClick={() => {
              setTimeout(() => {
                handle_open();
              }, 200);
            }}
          >
            Find a Nutritionist
          </Button>
        </Flex>
      </div>
    </div>
  );
}

export default HeadPage;
