import React, { useEffect, useState } from "react";
import styles from "./Blogs.module.scss";

import Button from "../../../Components/Button/Button";
import filter from "../../../Assets/Images/filters/blog_filter.png";

import { GetDate } from "../../../Functions/Dates";
import { NavLink, useNavigate } from "react-router-dom";
import customAxios from "../../../Utils/axios/axios";
import ImgBack from "../../../Components/Image/ImgBack";

function Blogs() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    customAxios.get("/api/blogs/get-all").then((res) => {
      setBlogs(res.data.data);
    });
  }, []);

  return (
    <div className={styles.main}>
      <Header />

      <div className={styles.blogs}>
        {blogs[0] && <MainBlog data={blogs[0]} />}
        <div className={styles.sub_blogs}>
          {blogs[1] && <SubBlog data={blogs[1]} />}
          {blogs[2] && <SubBlog data={blogs[2]} />}
          {blogs[3] && <SubBlog data={blogs[3]} />}
        </div>

        {/* <div className={styles.sub_blogs}>
          {subBlogs.map((item, index) => {
            return <SubBlog data={item} key={index} />;
          })}
        </div> */}
      </div>
    </div>
  );
}

export const Header = () => {
  return (
    <div className={styles.head}>
      <div className={styles.txts}>
        <h1>
          Your Gateway to <br /> Fitness Excellence
        </h1>
        <span>Explore Our Fitness Press and Blogs</span>
      </div>

      <div className={styles.figure_line}>
        <span className={styles.square}></span>
        <span className={styles.line}></span>
        <span className={styles.square}></span>
      </div>

      <Button className={styles.btn}>
        <NavLink to="/trenapedia">Trenapedia</NavLink>
      </Button>
    </div>
  );
};

const MainBlog = ({ data }) => {
  const navig = useNavigate();

  const goto = () => {
    navig(`/trenapedia/${data._id}`);
  };

  return (
    <div className={styles.main_blog} onClick={goto}>
      <ImgBack src={data.image} alt={data.title} className={styles.img} />
      <img src={filter} alt={data.title} className={styles.filter} />
      <div className={styles.content}>
        <div className={styles.infos}>
          <h1>
            {data.title} <span>{GetDate(new Date(data.createdAt))}</span>
          </h1>
          <p>{data.description}</p>
        </div>
      </div>
    </div>
  );
};

const SubBlog = ({ data }) => {
  const navig = useNavigate();

  const goto = () => {
    navig(`/trenapedia/${data._id}`);
  };

  return (
    <div className={styles.blog} onClick={goto}>
      <ImgBack src={data.image} alt={data.title} className={styles.img} />
      <img src={filter} alt={data.title} className={styles.filter} />
      <div className={styles.content}>
        <div className={styles.infos}>
          <h1>{data.title}</h1>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
