import ImgBack from "Components/Image/ImgBack";
import { LoaderCircle, Plus, Trash, Trash2 } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import customAxios from "Utils/axios/axios";

function ImagesWithLabel({
  label = "",
  className = "",
  labelClassName = "",
  value = [],
  name = "Images",
  onChange = () => {},
}) {
  const add_img = (img) => {
    let newImages = [...value, img];
    onChange({ target: { name, value: newImages } });
  };

  const remove_img = (index) => {
    let newImages = value.filter((img, i) => i !== index);
    onChange({ target: { name, value: newImages } });
  };

  return (
    <div className={`w-full ${className}`}>
      <label
        className={`text-[1.1vw] mb-[0.8vw] block font-medium ${labelClassName}`}
      >
        {label}
      </label>
      <div className=" flex items-center justify-start flex-wrap">
        {value.map((img, index) => {
          return (
            <ExistImage
              src={img}
              key={index}
              alt="img"
              onClick={() => remove_img(index)}
            />
          );
        })}
        <AddImage add_img={add_img} />
      </div>
    </div>
  );
}

const ExistImage = ({ src = "", alt = "", onClick = () => {} }) => {
  return (
    <div className="w-[10vw] h-[9vw] mr-[1vw] mb-[1vw] bg-[#F4F5F5] rounded-[1vw] border-[2px] border-gray-300 relative overflow-hidden">
      <div
        onClick={onClick}
        className="w-[1.5vw] h-[1.5vw] bg-[#F4F5F5] rounded-full absolute top-[0.3vw] right-[0.3vw] cursor-pointer flex items-center justify-center"
      >
        <Trash2 color="#4F4F4F" className="w-[0.9vw] h-[0.9vw]" />
      </div>
      <ImgBack src={src} alt={alt} className="w-full h-full object-cover" />
    </div>
  );
};

const AddImage = ({ add_img = () => {} }) => {
  const [Image, setImage] = useState({
    url: "",
    uploading: false,
    done: false,
  });
  const inputRef = useRef(null);

  useEffect(() => {
    console.log(Image);
    if (Image.done) {
      add_img(Image.url);
      setImage({ url: "", uploading: false, done: false });
    }
  }, [Image]);

  const handleClick = () => {
    if (Image.uploading) return;
    inputRef.current.click();
  };

  const changeImg = (event) => {
    let file = event.target.files[0];
    UploadImg(file, Image, setImage);
  };

  return (
    <div
      className="w-[10vw] h-[9vw] bg-[#F4F5F5] mr-[1vw] mb-[1vw] rounded-[1vw] flex items-center justify-center cursor-pointer border-dashed border-[2px] border-gray-300"
      onClick={handleClick}
    >
      {!Image.uploading ? (
        <Plus color="#4F4F4F" className="w-[30%] h-[30%]" />
      ) : (
        <LoaderCircle
          color="#4F4F4F"
          className="w-[30%] h-[30%] animate-spin"
        />
      )}

      <input
        ref={inputRef}
        type="file"
        className="hidden"
        onChange={changeImg}
      />
    </div>
  );
};

const UploadImg = async (file, Image, SetImage) => {
  // Initial File before upload
  const formData = new FormData();
  formData.append("image", file);
  SetImage({ ...Image, uploading: true, done: false });

  // the axios upload
  return await customAxios
    .post("/api/upload/images/single", formData)
    .then((response) => {
      SetImage({
        ...Image,
        url: response.data.file,
        done: true,
        uploading: false,
      });
    })
    .catch((error) => {
      toast.error("Error Uploading Image");
    });
};

export default ImagesWithLabel;
