import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const UseHaveOneOfTheLinks = (links = []) => {
  const [isCurrentUrl, setIsCurrentUrl] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsCurrentUrl(links.indexOf(location.pathname) !== -1);
  }, [links, location]);

  return isCurrentUrl;
};

export default UseHaveOneOfTheLinks;
