import React from "react";
import Dialogue from "../../../Components/Dialogue/Dialogue";
import styles from "./LoginPopup.module.scss";
import { OnlyLoginRight } from "../../Auth/Auth";

function LoginPopup({ open = false, handle_close, openOther = () => {} }) {
  return (
    <Dialogue handleClose={handle_close} open={open}>
      <div className={styles.main}>
        <OnlyLoginRight openOther={openOther} />
      </div>
    </Dialogue>
  );
}

export default LoginPopup;
