import React from "react";
import styles from "./BeTrainerPopup.module.scss";
import Dialogue from "../../../Components/Dialogue/Dialogue";
import Flex from "../../../Components/Flex/Flex";
import Button from "../../../Components/Button/Button";

function BeTrainerPopup({ open = false, handle_close }) {
  return (
    <Dialogue handleClose={handle_close} open={open}>
      <div className={styles.main}>
        <div className={styles.header}>
          <h1>
            Be A <span className="red"> Coach </span> With Us
          </h1>
          <h3>Send us your contact informations and we will do the rest</h3>
        </div>
        <Flex className={styles.body}>
          <input type="text" placeholder="Your Full Name" />
          <input type="text" placeholder="Your Email" />
          <input type="text" placeholder="Your Phone Number" />
        </Flex>

        <Flex flex="end" className={styles.footer}>
          <Button>Submit</Button>
        </Flex>
      </div>
    </Dialogue>
  );
}

export default BeTrainerPopup;
