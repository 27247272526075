import SubmitBlack from "Components/Button/SubmitBlack/SubmitBlack";
import ItemsInputWithLabel from "Components/Input/ItemsInputWithLabel/ItemsInputWithLabel";
import useRefrechUser from "Hooks/useRefrechUser";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import customAxios from "Utils/axios/axios";

function CoachServices() {
  const { user } = useSelector((state) => state.UserReducer);
  const [loading, setLoading] = React.useState(false);
  const { refrechUser } = useRefrechUser();

  const [form, setForm] = React.useState({
    Services: [],
    SportFields: [],
  });

  useEffect(() => {
    if (user) {
      setForm({
        Services: user.Services,
        SportFields: user.SportFields,
      });
    }
  }, [user]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);
    customAxios
      .put(`/api/coache/update/${user._id}`, {
        ...form,
      })
      .then((res) => {
        toast.success("Updated successfully");
        refrechUser();
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error updating data");
        setLoading(false);
      });
  };

  return (
    <div>
      <ItemsInputWithLabel
        route="/api/services/get-all"
        label="Services"
        value={form.Services}
        name={"Services"}
        onChange={handleChange}
      />

      <hr className="my-[2vw]" />

      <ItemsInputWithLabel
        route="/api/sport/get-all"
        label="Ways of practicing"
        value={form.SportFields}
        name={"SportFields"}
        onChange={handleChange}
      />

      <SubmitBlack
        onClick={handleSubmit}
        className="ml-auto mt-[2vw]"
        loading={loading}
      >
        SAVE
      </SubmitBlack>
    </div>
  );
}

export default CoachServices;
