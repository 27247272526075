import React from "react";
import TextInput from "../TextInput/TextInput";
import styles from "./styles.module.scss";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";

function PasswordInput({
  placeholder = "",
  value = "",
  name = "",
  onChange = () => {},
  className = "",
}) {
  const [showPassword, setShowPassword] = React.useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.container}>
      <TextInput
        type={showPassword ? "text" : "password"}
        className={`${styles.input} ${className}`}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
      />
      <div className={styles.eye}>
        {showPassword ? (
          <IoEyeSharp onClick={togglePassword} />
        ) : (
          <IoEyeOffSharp onClick={togglePassword} />
        )}
      </div>
    </div>
  );
}

export default PasswordInput;
