import React from "react";
import TopSpacer from "../../../Components/TopSpacer/TopSpacer";
import H2 from "../../../Components/H/H2";
import Container1 from "../../../Components/Container/Container1";
import TopHeader from "./TopHeader";
import BodyPart from "./BodyPart";

function MySettings() {
  return (
    <Container1 className="pb-[5vw]">
      <TopSpacer />
      <H2 className="text-center py-[3vw]">My Settings</H2>
      <ContentPart />
    </Container1>
  );
}

const ContentPart = () => {
  return (
    <div className="w-full ">
      <TopHeader />
      <BodyPart />
    </div>
  );
};

export default MySettings;
