import React from "react";
import styles from "./AboutUs.module.scss";
import H1 from "../../Components/H/H1";

import Button from "../../Components/Button/Button";
import Flex from "../../Components/Flex/Flex";

import img1 from "../../Assets/Images/Tempo/about_page/img1.png";
import img2 from "../../Assets/Images/Tempo/about_page/img2.png";

import { services, service_type } from "./data";
import { NavLink } from "react-router-dom";
import usePopup from "../../Hooks/usePupup";
import FindTrainerPopup from "../Pupups/FindTrainerPopup/FindTrainerPopup";
import { GapNavbarSpacer } from "../../Layouts/Navbar/Navbar";
// hello

function AboutUs() {
  const [open, handle_open, handle_close] = usePopup();
  return (
    <div className={styles.main}>
      <GapNavbarSpacer />
      <FindTrainerPopup handle_close={handle_close} open={open} />
      <H1 className={styles.h1}>Welcome to the Trena Movement</H1>

      <div className={styles.section_text_img}>
        <div className={styles.part_content}>
          <p>
            Welcome to <strong>TRENA</strong>, where we're more than just a
            fitness platform. We're revolutionizing fitness in{" "}
            <span className="red">Tunisia</span>, we connect you with excellent
            fitness coaches for personalized training.
          </p>
          <p>
            Making it easier for you to find the perfect trainer. <br />{" "}
            Personalized fitness has never been this simple.
          </p>
          <Button size="big" onClick={handle_open}>
            Find a trainer
          </Button>
        </div>

        <div className={styles.part_image}>
          <img src={img1} alt="trena" />
        </div>

        <Button
          className={styles.part_button}
          size="small"
          onClick={handle_open}
        >
          Find a trainer
        </Button>
      </div>

      <Services services={services} />

      <div className={`${styles.section_text_img} ${styles.section_reversed}`}>
        <div className={styles.part_content}>
          <p>
            At <strong>TRENA</strong>, it's more than fitness; it's about
            embracing a vibrant and healthier lifestyle. Our high-quality
            workout gear and equipment have been meticulously designed to fuel
            your workouts.
          </p>
          <p>
            redefining fitness fashion. Elevate your workout style, where we
            craft premium apparel designed for calisthenics & Street Workout
            enthusiasts.
          </p>
          <Flex flex="end">
            <Button size="big" color="black" className={styles.black}>
              <NavLink
                to="https://trena.store/"
                target="_blank"
                rel="noreferrer"
              >
                Shop Now
              </NavLink>
            </Button>
          </Flex>
        </div>

        <div className={styles.part_image}>
          <img src={img2} alt="trena" />
        </div>

        <Button
          size="big"
          color="black"
          className={`${styles.black} ${styles.part_button}`}
        >
          <NavLink to="https://trena.store/" target="_blank" rel="noreferrer">
            Shop Now
          </NavLink>
        </Button>
      </div>
    </div>
  );
}

export const Services = ({
  className = "",
  services = [],
  with_icon = true,
}) => {
  return (
    <div className={`${styles.services} ${className}`}>
      {services.map((item, index) => {
        return <Service data={item} key={index} with_icon={with_icon} />;
      })}
    </div>
  );
};

export const Service = ({ data = service_type, with_icon = true }) => {
  return (
    <div className={styles.service}>
      {with_icon && (
        <div className={styles.img_cont}>
          <img src={data.img} alt="" />
        </div>
      )}
      <h3>{data.title}</h3>

      <p>{data.desceiption}</p>
    </div>
  );
};

export default AboutUs;
