import { LoaderCircle, Save } from "lucide-react";
import React from "react";

function SubmitBlack({
  className = "",
  onClick = () => {},
  children = "Submit",
  disabled = false,
  loading = false,
  Icon = Save,
}) {
  return (
    <button
      className={`
        w-fit text-[1vw] py-[0.8vw] px-[5vw] 
        bg-black text-white rounded-full 
        flex items-center justify-center 
        transition duration-300 ease-in-out
        ${loading ? "opacity-50" : ""}
        ${className}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {!loading && <Icon className="mr-[0.5vw] h-[1.3vw] w-[1.3vw]" />}
      {loading && (
        <LoaderCircle className="mr-[0.5vw] h-[1.3vw] w-[1.3vw] animate-spin" />
      )}
      {children}
    </button>
  );
}

export default SubmitBlack;
