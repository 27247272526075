import { useDispatch } from "react-redux";
import { GetUserByToken } from "Store/user.reducer";

function useRefrechUser() {
  const dispatch = useDispatch();

  const refrechUser = () => {
    dispatch(GetUserByToken(() => {}));
  };
  return { refrechUser };
}

export default useRefrechUser;
