import React from "react";
import HeadPage from "./HeadPage/HeadPage";
import About from "./About/About";
import Trainers from "./Trainers/Trainers";
import Blogs from "./Blogs/Blogs";
import Nutretions from "./Nutretions/Nutretions";

function Home() {
  return (
    <div>
      <HeadPage />
      <Trainers />
      <Nutretions />
      <Blogs />
      {/* <About /> */}
    </div>
  );
}

export default Home;
