import React from "react";

function InputWithLabel({
  label = "",
  type = "text",
  className = "",
  labelClassName = "",
  inputClassName = "",
  ...props
}) {
  return (
    <div className={`w-full ${className}`}>
      <label
        className={`text-[1.1vw] mb-[0.8vw] block font-medium ${labelClassName}`}
      >
        {label}
      </label>
      <input
        {...props}
        type={type}
        className={`text-[1.1vw] px-[1.5vw] py-[0.8vw] rounded-[0.8vw] w-full border-border border transition-all duration-200  ${inputClassName}`}
      />
    </div>
  );
}

export default InputWithLabel;
