import React from "react";
import Generalinfo from "./Sections/Generalinfo";
import CertifsIno from "./Sections/CertifsIno";
import SocialInfo from "./Sections/SocialInfo";
import NutriServices from "./Sections/NutriServices";
import CoachServices from "./Sections/CoachServices";
import PasswordChange from "./Sections/PasswordChange";

const sections = {
  COACH: [
    {
      name: "General info",
      Component: <Generalinfo role="coache" />,
    },
    {
      name: "Certifications",
      Component: <CertifsIno role="coache" />,
    },
    {
      name: "Social Links",
      Component: <SocialInfo role="coache" />,
    },
    {
      name: "Services",
      Component: <CoachServices />,
    },
    {
      name: "Password",
      Component: <PasswordChange />,
    },
  ],
  NUTRITIONIST: [
    {
      name: "General info",
      Component: <Generalinfo role="nutritionist" />,
    },
    {
      name: "Certifications",
      Component: <CertifsIno role="nutritionist" />,
    },
    {
      name: "Social Links",
      Component: <SocialInfo role="nutritionist" />,
    },
    {
      name: "Services",
      Component: <NutriServices />,
    },
    {
      name: "Password",
      Component: <PasswordChange />,
    },
  ],
  USER: [
    {
      name: "General info",
      Component: <div>General info</div>,
    },
    {
      name: "Details",
      Component: <div>Details</div>,
    },
    {
      name: "Password",
      Component: <PasswordChange />,
    },
  ],
};

export default sections;
