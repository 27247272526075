import React from "react";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";

function PasswordWithLabel({
  label = "",
  type = "text",
  className = "",
  labelClassName = "",
  inputClassName = "",
  icon = "/svgs/rxs/ww.svg",
  ...props
}) {
  const [showPassword, setShowPassword] = React.useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`w-full ${className}`}>
      <label
        className={`text-[1.1vw] mb-[0.8vw] block font-medium ${labelClassName}select-none`}
      >
        {label}
      </label>
      <div className="rounded-[0.8vw] pr-[1vw] w-full flex items-center justify-start  border-border border overflow-hidden">
        <input
          {...props}
          type={showPassword ? "text" : "password"}
          className={`text-[1.1vw] px-[1.5vw] py-[0.8vw] w-full outline-none  ${inputClassName}`}
        />
        <div
          className="w-[2vw] flex items-center justify-center cursor-pointer"
          onClick={togglePassword}
        >
          {showPassword ? (
            <IoEyeSharp className="w-[1.5vw] h-[1.5vw]" />
          ) : (
            <IoEyeOffSharp className="w-[1.5vw] h-[1.5vw]" />
          )}
        </div>
      </div>
    </div>
  );
}

export default PasswordWithLabel;
