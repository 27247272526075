import React from "react";
import { IoCloseSharp } from "react-icons/io5";

export const CloseButton = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="bg-mainColorLight rounded-full right-[2vw] md:right-[0.7vw] top-[2vw] md:top-[0.7vw] text-[5vw] md:text-[2vw] h-[6vw] md:h-[2.2vw] w-[6vw] md:w-[2.2vw] absolute z-[5] flex items-center justify-center cursor-pointer"
    >
      <IoCloseSharp className="text-mainColorDark" />
    </div>
  );
};

export default CloseButton;
