import React from "react";

function SelectWithLabel({
  label = "",
  className = "",
  labelClassName = "",
  inputClassName = "",
  options = [],
  ...props
}) {
  return (
    <div className={`w-full ${className}`}>
      <label
        className={`text-[1.1vw] mb-[0.8vw] block font-medium ${labelClassName}`}
      >
        {label}
      </label>
      <select
        {...props}
        className={`text-[1.1vw] px-[1.5vw] py-[0.8vw] rounded-[0.8vw] w-full border-border border transition-all duration-200  ${inputClassName}`}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectWithLabel;
