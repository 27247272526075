import shield from "../../Assets/Svgs/about/shield.svg";
import train from "../../Assets/Svgs/about/train.svg";
import tshirt from "../../Assets/Svgs/about/tshirt.svg";

export const services = [
  {
    title: "Active Wear",
    img: tshirt,
    desceiption: `Explore our performance-driven active wear designed for comfort, style, and functionality, empowering your workouts.`,
  },
  {
    title: "Personalized",
    img: train,
    desceiption: `We tailor workouts to your unique goals and adapt as you progress, ensuring a customized fitness journey.`,
  },
  {
    title: "Certified Trainers",
    img: shield,
    desceiption: `Our diverse team of certified trainers provides motivation and expertise, helping you reach your fullest potential.`,
  },
];

export const service_type = {
  title: "",
  img: "",
  desceiption: ``,
};
