import React from "react";
import styles from "./H.module.scss";

function TitleWithLine({ children, is_white = false, className = "" }) {
  let is_white_style = is_white ? styles.is_white : "";
  return (
    <div className={`${styles.head} ${is_white_style} ${className}`}>
      <h1>{children}</h1>

      <figure className={styles.figure_line}>
        <span className={styles.square}></span>
        <span className={styles.line}></span>
        <span className={styles.square}></span>
      </figure>
    </div>
  );
}

export default TitleWithLine;
